@import "../../../styles/colors";

.flex_radio_button {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border: 1px solid $gray-1;
  border-radius: .25rem;
  padding: 5px;
}

.flex_radio_button div {
  width: 50%;
  margin: 5px 0;
}

.flex_radio_button div label, .flex_radio_button div input {
  cursor: pointer;
}

@media(max-width: 1100px) {
  .flex_radio_button div {
    width: 100%;
  }
}