// App Colors
$black: #333333;
$cyan: #4bbbef;
$gray-1: #adb6bd;
$gray-2: #e8ecef;
$gray-3: #e8ecef;
$gray-4: #e8ecef;
$gray-5: #b7b7b7;
$gray-6: #666666;
$gray-7: #999999;
$green-1: #2bcfbc;
$green-2: #4cbf88;
$orange-1: #fd802b;
$orange-2: #fcb045;
$peach: #ef7b5d;
$pink: #fbc7d4;
$purple-1: #e5e9f2;
$purple-2: #9b9ccc;
$purple-3: #9796f0;
$purple-4: #2329d6;
$red: #fd1d1d;
$white: #ffffff;
$yellow: #f6c035;
$yellow-2: #ffe060;

$dark: #2d2842;
$primary: $purple-1;
$accent: $purple-4;
$logo: #fd6731;
