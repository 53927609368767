@import "../../styles/colors";

.dFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4em 0;
}


.textCenter {
  text-align: center;
}

.textPurple {
  color: $accent
}

.buttonLogin {
  background-color: rgba($logo, .8);;
  color: white;
  border: 0;
  width: 30%;
  max-width: 30%;
  &:hover {
    background-color: rgba($logo, 1);
  }
}

