@import "../../styles/colors";

.title {
  font-weight: 300;
  font-size: 24px;
}

.card {
  width: auto;
  padding: 25px;
  margin: 1.5rem 0;
  border-radius: 15px;
  min-height: 500px;
}

.card_white {
  background-color: $white;
}

.flex_container {
  display: flex;
  flex-direction: row;
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  text-decoration: none;
  border: $purple-4 solid 1px;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  font-weight: bold;
  color: $purple-4;
}

@media (max-width: 768px) {
  .flex_container {
    flex-direction: column;
  }
}
