@import "../../styles/colors";

.footer {
  color: $gray-1;

  p {
    margin-top: 24px;
    margin-bottom: 0;
    padding-bottom: 24px;
    text-align: center;
  }
}
