@import "../../styles/colors";

.r_button {
  color: $black;
  font-weight: bold;
  background-color: $white;
  border: 1px solid $gray-1;
  border-radius: 32px;
  padding: 10px 30px;
  cursor: pointer;
}