@import "~react-vis/dist/style";
@import "../../styles/colors";
.section {
  margin: 20px;
}
.main {
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
.commonback {
  border-radius: 10px;
  background: #fff;
}
.videoSection {
  padding: 10px;
}
.videoContent {
  padding: 10px;
}
.title {
  font-size: 22px;
  line-height: 33px;
  color: #000;
  font-weight: 500;
  margin: 0;
}
.subtitle {
  font-size: 16px;
  line-height: 24px;
  color: #000;
  font-weight: 500;
  margin: 0;
}
.content {
  font-size: 14px;
  line-height: 21px;
  color: #000;
  font-weight: 300;
}
.heading {
  font-size: 14px;
  line-height: 21px;
  color: #000;
  margin: 20px 0;
  font-weight: 300;
  position: relative;
}
.selectSpan {
  margin-left: 10px;
}
.videoSelect {
  min-width: 130px;
  width: auto;
  padding: 4px 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.form_control {
  margin-bottom: 10px;
  padding: 0.375rem 0.75rem;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.p_info {
  flex: 1;
  padding: 0 12px;
  text-align: left;
  width: 100%;
}
.qualitymain {
  display: flex;
  margin-top: 15px;
}

.p_info label {
  width: 65px;
}

.p_info input[type="time"],
.p_info input[type="text"] {
  width: 60px;
  flex-wrap: wrap;
  padding: 5px 0px 5px 12px;
  height: 21px;
  border-radius: 4px;
  border: 1px solid rgb(156, 155, 155);
}

.p_info input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
