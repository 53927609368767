@import "../../styles/colors";

.patient_title {
  font-weight: 300;
  font-size: 24px;
}

.card {
  width: auto;
  margin: 1.5rem 0;
  border-radius: 15px;
}

.card_white {
  background-color: $white;
}

.flex_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $white;
}

.table_header {
  padding: 25px;
  justify-content: space-between;
  border-radius: 15px;
}

.add_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $purple-4 !important;
  color: $white !important;
  margin: 0 0 0 10px;
  border-radius: 10px;
}

.add_button svg, span svg {
  margin: 0 5px 0 0;
}

.table_responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.patient_table {
  width: 100%;
  border-collapse: collapse;
}

.sortable {
  cursor: pointer;
}

.separator {
  align-items: center;
  color: $gray-1;
  border-bottom: 1px solid $gray-1;
  padding: 10px 0;
}

.table_row {
  border-bottom: 1px solid $gray-2;
  padding: 10px;
}

.table_footer {
  display: flex;
  justify-content: center;
  padding: 30px;
  color: $purple-4;
  font-weight: bold;
}

.fall_risk {
  background-color: $orange-1;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  line-height: 25px;
  color: $white;
  display: inline-block;
}

.risk_change_up {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 3px 10px;
  color: $red;
  border: 1px solid $red;
  border-radius: 20px;
}

.risk_change_down {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 3px 10px;
  color: $green-2;
  border: 1px solid $green-2;
  border-radius: 20px;
}

.action_button {
  color: $orange-1;
  text-decoration: none;
  cursor: pointer;
}

.column_head {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

@media (max-width: 768px) {
  .flex_container {
    flex-direction: column;
  }

  .patient_title {
    margin-bottom: 10px;
  }

  .add_button {
    margin-top: 10px;
  }
}
