.alert_overlay {
    opacity: .8;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0;
    right: 0;
    background: #FFF;
    width: 100%;
    z-index: 10;
}

.alert_box {
    background: #fff;
    width: 410px;
    height: 200px;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    position: fixed;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 57%);
    transition: 0.3s;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.alert_box_header{
	background: #21CDC0;
	width: 410px;
	height: 40px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	display: flex;
	align-items: center;

}
.alert_box_header h1{
	font-size: 18px;
	color: #fff;
	padding-left: 10px;
	margin: 0;
}
.alert_box_body{
	padding: 45px 80px;
}

