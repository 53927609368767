@import "../../../styles/colors";

.f_input {
  opacity: 0;
  color: $black;
}

.no_image {
  width: 100%;
  height: 20vw;
  background-color: $gray-2;
  border-radius: 5px;
  margin: 3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploaded_image {
  width: 100%;
  height: 20vw;
  border-radius: 5px;
  margin: 3px 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  border: 1px solid $gray-2;
}

@media (max-width: 768px) {
  .flex_container {
    flex-direction: column;
  }

  .no_image, .uploaded_image {
    height: 70vw;
  }
}
