@import "../../../styles/colors";

.search_container {
  display: flex;
  flex-direction: row;
  border: 1px solid $gray-1;
  border-radius: 10px;
  padding: 5px 10px ;
  align-items: center;
}

.s_input {
  padding: .1rem .1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $black;
  background-color: $white;
  background-clip: padding-box;
  border: none;
  box-sizing: border-box;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:focus {
    outline: none;
  }
}
