.background {
  margin: auto 7rem;
  height: 100%;

}

@media screen and (max-width: 768px) {
  .background {
    margin: auto 2rem;
  }
}
