@import "../../styles/colors";

.homeContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-template-areas: "message charts charts";
  min-height: calc(100vh - 155px);
  padding: 12px;
}

.homeMessage {
  grid-area: message;

  small {
    color: $gray-1;
  }

  h2 {
    font-size: xx-large;
  }

  span {
    font-size: 18px;
    font-weight: 100;
    color: $gray-6;
  }
}

.card {
  width: auto;
  margin: 0;
  border-radius: 15px;
  background-color: $white;
}

.cardContent {
  padding: 20px;
}

.detailButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.barsContainer {
  margin: 12px;
}

.barsContent {
  margin: 0 auto;
  background-color: $gray-2;
  border-radius: 5px;
}

.barsInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 24px;
}

@mixin barsFill {
  padding: 4px 0;
  border-radius: 5px;
}

.barsFillLow {
  @include barsFill;
  background-color: $green-2;
}

.barsFillMedium {
  @include barsFill;
  background-color: $yellow-2;
}

.barsFillHigh {
  @include barsFill;
  background-color: $peach;
}

button.detailButton {
  border-color: $purple-4;
  color: $purple-4;
  border-radius: 10px;
  padding: 10px 10px;
  font-weight: normal;
}

button.detailButtonMargin {
  margin-right: 20px;
  margin-bottom: 20px;
}

.homeChart {
  grid-area: charts;
}

.homeChartInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
}

.homeChartInfoTitle {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 24px;

  select {
    border: 0;
    outline: 0;
    color: $purple-4;
    justify-self: end;
  }
}

.homeChartEngagement {
  grid-column-end: span 2;
}

.avatarImage {
  width: 50px;
  border-radius: 50%;
}

.homeRecentsContent {
  display: flex;
  flex-direction: column;
  margin: 24px 0;
}

.homeRecentsItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}

.homeRecentItemsAvatar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.homeRecentItemsAvatarName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
}

.homeRecentItemsData {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.homeRecentFallRisk {
  background-color: $peach;
  color: $white;
  padding: 4px 8px;
  border-radius: 50%;
  margin-right: 8px;
}

@mixin homeRecentRiskChange {
  padding: 2px 8px;
  border-radius: 12px;
}

.homeRecentRiskChangeUp {
  @include homeRecentRiskChange;
  border: 1px solid $peach;
  color: $peach;
}

.homeRecentRiskChangeDown {
  @include homeRecentRiskChange;
  border: 1px solid $green-2;
  color: $green-2;
}

.homeRecentControl {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    margin: 0 2px;
  }
}

.arrow {
  color: $purple-4;
  padding: 6px;
}

.dots {
  color: $gray-6;
}

.lightFont {
  color: $gray-7;
}

.detailButton:hover{
  background-color: #2b2929;
  color: white;
  border-color: #2b2929;
}

.detailButton:active{
  background-color: #e47979;
  color: white;
  border-color: #e47979;
}
