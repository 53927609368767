@import "../../styles/colors";

.container {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: $purple-1;
}

.fullScreen {
  position: absolute;
  top: 0;
  left: 0;
}

.preloader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, $logo);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}
