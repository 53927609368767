@import "../../styles/colors";

.title {
  font-weight: 300;
  font-size: 24px;
}

.card {
  width: auto;
  padding: 25px;
  margin: 1.5rem 0;
  border-radius: 15px;
}

.card_white {
  background-color: $white;
}

.r_hr {
  margin: 30px 0;
  border: 1px solid $gray-2;
}

.form_group {
  margin-bottom: 1rem;
}

.form_control {
  display: block;
  width: 100%;
}

.flex_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.flex_container_reverse {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.p_info {
  flex: 1;
  padding: 2px 5px;
}

@media (max-width: 768px) {
  .card {
    padding: 5%;
  }

  .flex_container {
    flex-direction: column;
  }
}

.required {
  color: #f00;
}
