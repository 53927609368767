@import "../../styles/colors";

.navbarContainer {
  background: $purple-1;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  height: auto;
}

.navbarLogo {
  margin: auto 1rem;
}

.navbarLogoLink {
  font-family: "Baloo 2";
  font-weight: 800;
  color: $logo;
  text-decoration: none;
  font-size: 2.5rem;
}

.navbarToggle {
  display: none;
}

.navbarItem {
  margin: auto 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin: auto 8px;
    color: $black;
  }
}

.navbarMenu {
  display: flex;
  flex-direction: row;
  padding: 0;
  list-style-type: none;
}

.menuItem {
  a {
    padding: 8px 12px;
    text-decoration: none;
    color: $purple-2;

    &:hover {
      color: $purple-4;
      border-bottom: 2px solid $purple-4;
    }
  }
}

.menuItemSelected {
  a {
    color: $purple-4;
    border-bottom: 2px solid $purple-4;
  }
}

.navbarIcon {
  font-size: 2rem;
  color: $purple-2;
}

.navbarPictureIcon {
  border-radius: 50%;
  border: 3px solid $white;
  box-shadow: 2px 2px 2px $gray-1;
  width: 35px;
}

.textLogout {
  color: $purple-4 !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
}

.dFlex {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .navbarContainer {
    grid-template-columns: auto auto;
    grid-template-areas:
      "menuShow menuShow"
      "menuHide menuHide";
  }

  .navbarToggle {
    margin: auto 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .navbarItem {
    grid-column: 1 / span 2;
    margin: 0 auto 12px;
  }

  .navbarItemHide {
    display: none;
  }

  .navbarMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menuItem {
    margin-top: 18px;
  }
}
