@import "../../styles/colors";

.videoContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.videoContent {
  grid-column-start: 2;
  grid-column-end: 3;
}

.center {
  text-align: center;
}

.titlePurple {
  color: $purple-2;
}

.body {
  text-align: justify;
}

.body2 {
  text-align: center;
}

@mixin button {
  margin-top: 12px;
  width: 100%;
}

.buttonFill {
  @include button;
  border: 1px solid $purple-4;
  background-color: $purple-4;
  color: $white;
  margin-bottom: 12px;
}

.buttonEmpty {
  @include button;
  border: none;
  background-color: transparent;
  color: $purple-4;
  margin-top: 0;
}

.buttonEmptyFill {
  @include button;
  border: 1px solid $purple-4;
  background-color: transparent;
  color: $purple-4;
}

.addPipeButton {
  @include button;
  label[for="pipe-file-input-video"] {
    background: $purple-4;
    border: 1px solid $purple-4;
    border-radius: 32px;
    color: $white;
    font-weight: bold;
    padding: 10px 30px;
  }
}

.videoContentExample {
  text-align: center;
  iframe {
    width: 100%;
    height: 360px;
  }
}

@mixin icon {
  font-size: 36px;
}

.successIcon {
  @include icon;
  color: $green-2;
}

.failIcon {
  @include icon;
  color: $peach;
}

@media screen and (max-width: 768px) {
  .videoContainer {
    grid-template-columns: 1fr;
  }

  .videoContent {
    grid-column: 1;
    padding: 24px;
  }

  .videoContentExample {
    iframe {
      height: 240px;
    }
  }
}
