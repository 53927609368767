@import "../../../styles/colors";

.d_input {
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $black;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $gray-1;
  border-radius: .25rem;
  box-sizing: border-box;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
