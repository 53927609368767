@import "~react-vis/dist/style";
@import "../../styles/colors";

.title {
  font-weight: 300;
  font-size: 24px;
}

.subtitle {
  font-weight: 500;
  font-size: 18px;
}

.card {
  width: auto;
  padding: 12px;
  margin: 1.5rem 0;
  border-radius: 15px;
}

.cardWhite {
  background-color: $white;
}

.cardDark {
  background-color: $dark;
}

.cardGreen {
  background-color: $green-2;
}

.cardOrange {
  background-color: $peach;
}

.cardPurple {
  background: linear-gradient(to right, $purple-3, $pink);
}

.cardPatientInfo {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.cardPatientInfoItem {
  margin: auto 10px;
  padding: 12px 0;
}

.cardPatientInfoAvatar {
  border-right: 2px solid $gray-2;
  display: grid;
  grid-template-columns: auto;
  gap: 12px;
  justify-items: center;
}

.cardPatientInfoText {
  display: grid;
  grid-template-columns: auto;
  gap: 12px;
  justify-items: left;
  overflow-wrap: anywhere;
}

.cardPatientTextSpace {
  grid-row-start: 2;
  grid-row-end: span 2;
}

.cardSubtitleText {
  color: $gray-1;
}

.cardTitleText {
  font-size: medium;
  font-weight: 700;
}

.cardPatientInfoAvatarImage {
  width: 50px;
  border-radius: 50%;
}

.cardTextCollapse {
  white-space: initial;
  overflow: hidden;
}

.btnOutline {
  border: 1px solid black;
  background-color: white;
  color: black;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  margin-right: 5px;
  margin-bottom: 5px;
}

.btnPurple {
  border-color: $purple-4;
  color: $purple-4;
  background-color: transparent;

  &:hover {
    background-color: $purple-4;
    color: $white;
  }
}

.btnPurpleInv {
  border-color: $purple-4;
  background-color: $purple-4;
  color: $white;

  &:hover {
    background-color: transparent;
    color: $purple-4;
  }
}

.cardGlance {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  margin: 0;
}

.cardPatientSummary {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 12px;
  color: $white;
}

.cardPatientSummaryTitle {
  font-weight: 700;
}

.cardPatientSummaryValue {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cardPatientSummaryValueVal {
  font-weight: 300;
  font-size: x-large;
}

.cardPatientSummaryValueSymbol {
  font-weight: 400;
  font-size: small;
}

.cardPatientTips {
  padding: 12px;
  padding-right: 7rem;
  color: $white;
}

.cardPatientTipsTitle {
  font-size: large;
  font-weight: bold;
  margin-top: 0;
}

.cardPatientData {
  display: grid;
  grid-template-columns: auto;
  color: $white;
}

.cardPatientDataCompact {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 12px;
}

.cardPatientDataItem {
  margin-top: 0;
  margin-bottom: 12px;
  color: $black;
  height: 340px;
}

.patientChartTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  select {
    border: 0;
    outline: 0;
    color: $purple-4;
  }
}

.patientChartLegend {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 12px 0;

  li {
    margin-left: 21px;

    span {
      color: $gray-1;
    }
  }
}

.patientChartGraph {
  height: 250px;
}

.form_control {
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .cardPatientTips {
    padding-right: 7rem;
  }

  .cardPatientSummary {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .cardPatientDataCompact {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .cardPatientInfo {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .cardPatientInfoAvatar {
    grid-row-end: span 2;
  }
}

@media screen and (max-width: 768px) {
  .cardPatientInfo {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .cardPatientInfoAvatar {
    border-right: 0;
  }

  .cardPatientSummary {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .cardPatientTips {
    padding-right: 12px;
  }

  .cardPatientDataItem {
    height: 200px;
  }

  .patientChartGraph {
    height: 120px;
  }
}

.btn_div {
  gap: 20px;
}

.btn_div_area {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 16px;
  right: 0;
  button {
    margin-right: 20px;
  }
}

.div_area textarea {
  height: 60px;
  width: 250px;
  margin-top: 5px;
}

.roxie_video {
  display: flex;
  flex-wrap: wrap;
}

.roxie_video > div {
  margin: 10px;
  text-align: center;
}

.roxie_video video {
  width: 300px;
  margin-bottom: 10px;
}

.roxie_video h2 {
  text-align: center;
}

.p_info {
  flex: 1;
  padding: 0 5px;
  text-align: left;
  width: 100%;
}

.p_info label {
  font-size: 12px;
  margin-right: 10px;
}

.p_info select {
  width: 150px;
  flex-wrap: wrap;
  padding: 3px 5px;
  height: 24px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid rgb(156, 155, 155);
}

.p_info input[type="time"] {
  width: 50px;
  flex-wrap: wrap;
  padding: 0 5px;
  height: 21px;
  border-radius: 4px;
  border: 1px solid rgb(156, 155, 155);
}

.p_info input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.form_group {
  margin-bottom: 1rem;
}
.right_align {
  text-align: right;
  width: 38%;
}
.left_align {
  text-align: left;
  margin-left: 110px;
  margin-top: -19px;
}

.diffTime {
  width: 36px;
  height: 14px;
  padding: 4px 12px;
  border: 1px solid #a1a1a1;
  border-radius: 4px;
  font-size: 0.8rem;
}
